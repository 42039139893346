<template>
  <div>
     <b-card no-body>
        <b-card-header>
            <b-card-title>Update Profile</b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
            <div class="row">
                <div class="col-md-12">
                    <p>Basic Account</p>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Name:</label>
                        <input type="text" class="form-control" v-model="formPayload.name" placeholder="User name...">
                        <small class="text-danger float-right" v-if="validation.name && Array.isArray(validation.name) && validation.name.length > 0">{{ validation.name.join(', ') }}</small>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Email:</label>
                        <input type="text" class="form-control" v-model="formPayload.email" placeholder="User email...">
                    </div>
                </div> -->
                <div class="col-md-12">
                    <br>
                    <p>Change Password</p>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Old Password:</label>
                        <input type="password" class="form-control" v-model="formPayload.old_password" placeholder="Old password...">
                        <small class="text-danger float-right" v-if="validation.old_password && Array.isArray(validation.old_password) && validation.old_password.length > 0">{{ validation.old_password.join(', ') }}</small>
                    </div>
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">New Password:</label>
                        <input type="password" class="form-control" v-model="formPayload.password" placeholder="New password...">
                        <small class="text-danger float-right" v-if="validation.password && Array.isArray(validation.password) && validation.password.length > 0">{{ validation.password.join(', ') }}</small>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="">Confirm New Password:</label>
                        <input type="password" class="form-control" v-model="formPayload.password_confirmation" placeholder="New password confirmation...">
                        <small class="text-danger float-right" v-if="validation.password_confirmation && Array.isArray(validation.password_confirmation) && validation.password_confirmation.length > 0">{{ validation.password_confirmation.join(', ') }}</small>
                    </div>
                </div>
                <div class="col-md-6">
                    <br>
                    <button class="btn btn-success" @click="saveProfile()">Save Profile</button>
                </div>
            </div>
        </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  title () {
    return `Profile`
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      formPayload: Object,
      validation: {},
    }
  },
  watch: {
    formPayload: {
      handler(val){
        this.validation = {};
      },
      deep: true
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
        this.$http.get('/me')
        .then(response => {
            var userData = response.data.data;
            localStorage.setItem('userData', JSON.stringify(userData))

            this.formPayload = userData
        })
                        },
    saveProfile() {
        this.$http.post('/me/', this.formPayload)
        .then(response => {

            this.loadData()
            successNotification(this, 'Success', 'Profile successfully updated!')

        })
        .catch(error => {
          this.validation = error.response.data.meta.messages;
        })
    }
  },
}
</script>

<style>

</style>
